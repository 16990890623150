.search-container{
    width:100%;
    display:flex;
    flex-direction: column;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
}

.logo{
    margin:0;
    width:300px;
}