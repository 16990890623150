.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.ul-password-check {
  list-style: none;
  margin-bottom: 0px;
  padding-left: 24px;
}

.ul-password-check .li-ok:before {
  content: '✓';
  color: green;
}

.ul-password-check .li-ok {
  color: green;
}

.ul-password-check .li-ko:before {
  content: 'X ';
  color: red;
  font-weight: bold;
}

.ul-password-check .li-ko {
  color: red;
  font-weight: bold;
}

.navbar-custom {
  margin-bottom: 0px !important;
}

.modal-backdrop {
  z-index: 1!important;
}

.nav-link-disabled {
  color: #ccc !important;
}

.inactive-link {
  pointer-events: none;
  cursor: default;
}

@media (min-width: 1000px) {
#searchbar-ville{
  margin-right:20px;
  z-index: 100;
  position: absolute;
}

#searchbar-ville > .search-form {
  margin-right:15px
}}