.search-form {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  justify-content: center;
}

.search-suggestion {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin:0;
  padding: 0;
}

.search-bar > input {
  background-color:transparent;
}

.search-bar {
  cursor: text;
  margin: 0px;
  border: 1px solid #dfe1e5;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 24px;
  word-wrap: break-word;
  padding-left: 3rem;
  padding-right: 1.5rem;
  outline: none;
  display: flex;
  flex: 100%;
  flex-wrap: wrap;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  -webkit-tap-highlight-color: transparent;
  min-height: 46px;
  background: url(../searchicon.svg) no-repeat scroll 12px 12px;
  background-size: 48px 48px;
  background-color: #fff;
}

.search-bar:hover {
  background-color: #fff;
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
  border-color: rgba(223, 225, 229, 0);
}


#searchfield {
  justify-self: center;
}

.search-input {
  outline: none;
  width: 100%;
  min-height: 46px;
  border: none;
  font-size: 16px;
}

.selectedOn {
  background-color: #fff;
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
  border-color: rgba(223, 225, 229, 0);
}
