.suggestion{
    cursor:pointer;
    width:90%;
    font-size:16px;
    padding-bottom: 10px;
    padding-top:5px;
    background: url(../searchicon.svg) no-repeat scroll 5px 6px; 
    background-size:32px 32px;
    padding-left:25px
}

.suggestion:hover{
    background-color:#EEEEEE
}

.suggestion:focus{
    outline: none;
    background-color:#EEEEEE
}

#search-bar > div:nth-child(2) > div{
    border-top:1px solid #EEEEEE;
}